<template>
  <div>
    <v-container class="mt-8">
      <v-row v-for="message in consultationThreadMessages" :key="message.id">
        <section class="d-flex-inline flex-row justify-center mb-5">
          <span
            :class="[
              message.sender.email === user.email
                ? 'justify-end'
                : 'justify-start',
              'd-flex text-caption'
            ]"
          >
            {{ message.sender.first_name }}
            <span class="primary--text px-2 font-weight-black "> | </span>

            {{ message.created_at | toLocalTZ | moment('D, MMM YYYY, h:mm a') }}
          </span>
          <div
            :class="[
              message.sender.email === user.email
                ? 'justify-end'
                : 'justify-start',
              'd-flex '
            ]"
          >
            <div
              :class="[
                message.sender.email === user.email
                  ? 'talk-right__simple'
                  : 'talk-left__simple',
                'card-bubble text-body-2 '
              ]"
            >
              <div class=" white--text pa-3">
                {{ message.text }}
              </div>
            </div>
          </div>
          <span
            :class="[
              message.sender.email === user.email
                ? 'justify-end'
                : 'justify-start',
              'd-flex px-4 py-2'
            ]"
          >
            <a
              v-for="(file, i) in message.attached_files"
              :key="i"
              :href="file.url"
              :download="file.url"
              class=" d-flex flex-column text-decoration-none col-3"
            >
              <v-icon color="primary">
                mdi-file-outline
              </v-icon>
              <span class="caption secondary--text  file-name "
                >{{ file.name }}
              </span>
            </a>
          </span>
        </section>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'

export default Vue.extend({
  name: 'ConsultationChat',
  props: {
    consultationThreadMessages: {
      required: true,
      type: Array
    }
  },
  computed: {
    ...mapGetters(['user'])
  }
})
</script>
<style lang="scss">
@mixin shadow-2dp() {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.chat {
  height: 46vh;
  overflow-y: scroll;
}
section {
  width: 100vw;
}
.file-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}
.card-bubble {
  min-height: 0px;
  width: 70%;
  max-width: 600px;

  @include shadow-2dp();
}

.card-bubble.talk-left__simple {
  border-radius: 0px 20px 20px 20px;
  float: left;
  background-color: #538e71;
}

.card-bubble.talk-right__simple {
  border-radius: 20px 0px 20px 20px;
  float: right;
  background-color: #797979;
}
</style>
