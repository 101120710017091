<template>
  <v-container grid-list-s class="pt-4">
    <v-row>
      <v-col>
        <h2 class="text-h4 font-weight-bold primary--text">
          {{ $t('consultationsTitle') }}
        </h2></v-col
      >
      <v-col>
        <div class="justify-end d-flex">
          <v-btn depressed color="primary" @click="openCreateConsultation">
            {{ $t('createConsultation') }}
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col col="4" md="4" sm="4" class="consult_list">
        <v-list two-line>
          <v-list-item-group v-model="selected" active-class="primary--text">
            <template v-if="!consultations.length">
              <div>{{ $t('consultationThreadsEmpty') }}</div>
            </template>
            <template v-for="(item, index) in consultations">
              <v-list-item :key="item.title" @click="openItem(item.id)">
                <template>
                  <v-list-item-content>
                    <v-list-item-title v-text="item.title"></v-list-item-title>
                    <v-list-item-subtitle
                      v-text="
                        item.messages &&
                          item.messages[item.messages.length - 1].text
                      "
                    ></v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-list-item-action-text>{{
                      item.created_at | toLocalTZ | moment('from', 'now')
                    }}</v-list-item-action-text>

                    <v-badge
                      v-if="checkIfIsNotSeen(item)"
                      dot
                      color="primary"
                      class="ma-5"
                    />
                  </v-list-item-action>
                </template>
              </v-list-item>

              <v-divider
                v-if="consultations && index < consultations.length - 1"
                :key="index"
              ></v-divider>
            </template>
          </v-list-item-group>
        </v-list>
        <v-row v-if="loadMore" justify="center" class="my-10">
          <v-btn depressed color="primary" @click="getMoreConsultations">
            {{ $t('loadMore') }}
          </v-btn>
        </v-row>
      </v-col>
      <v-divider vertical />
      <v-col col="7" md="7" sm="7">
        <div v-if="currentConsultationThread && showCurrentConsultation">
          <h3 class="secondary--text">{{ currentConsultationThread.title }}</h3>
          <v-row class="chat">
            <v-col>
              <ConsultationChat
                :consultation-thread-messages="currentThreadMessages"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pb-0">
              <v-text-field
                v-model="reply"
                append-outer-icon="mdi-send"
                clear-icon="mdi-close-circle"
                clearable
                label="Message"
                type="text"
                @click:append-outer="sendMessage"
                @click:clear="clearMessage"
              />
            </v-col>
          </v-row>
          <v-row class="pt-0">
            <v-col class="pt-0">
              <v-file-input
                v-model="attachedFiles"
                append-outer-icon="mdi-file"
                prepend-icon=""
                class="pt-0"
                multiple
                chips
                small-chips
              >
              </v-file-input>
            </v-col>
          </v-row>
        </div>
        <v-form
          v-else-if="createIsOpen"
          ref="form"
          lazy-validation
          @submit.prevent="createUserConsultation"
        >
          <v-text-field
            v-model="title"
            outlined
            type="text"
            :label="$t('subject')"
            color="primary"
            maxlength="255"
            required
            :rules="[titleRules]"
            @input="resetValidation"
          />
          <v-textarea
            v-model="message"
            solo
            name="input-7-4"
            :label="$t('message')"
            required
            :rules="[messageRules]"
            @input="resetValidation"
          ></v-textarea>

          <v-btn
            depressed
            color="primary"
            type="submit"
            class="px-14 text-capitalize text-lg-h5 font-weight-bold float-right"
          >
            {{ $t('send') }}
          </v-btn>
          <v-row v-if="nonFieldErrors" class="error--text" justify="center">{{
            nonFieldErrors
          }}</v-row>
        </v-form>
        <div v-else-if="consultations.length">
          {{ $t('selectConsultation') }}
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import Vue from 'vue'

import { mapActions, mapState } from 'vuex'
import ConsultationChat from '@/components/ConsultationChat.vue'

export default Vue.extend({
  components: {
    ConsultationChat
  },
  data: () => ({
    selected: [2],
    createIsOpen: false,
    title: '',
    message: '',
    reply: '',
    marker: true,
    showCurrentConsultation: true,
    titleError: '',
    messageError: '',
    nonFieldErrors: '',
    attachedFiles: null
  }),
  computed: {
    ...mapState({
      consultations: state => state.consultations.consultations,
      loadMore: state => state.consultations.consultationsLoadMore,
      currentConsultationThread: state =>
        state.consultations.currentConsultationThread,
      currentThreadMessages: state => state.consultations.currentThreadMessages,
      user: state => state.auth.user
    })
  },
  created() {
    this.getConsultations().then(() => {
      if (!this.consultations.length) this.openCreateConsultation()
    })
  },
  mounted() {
    this.scrollToEndOfConvo()
    this.onLoad()
  },
  beforeDestroy() {
    clearInterval(this.intervalId)
  },
  methods: {
    ...mapActions([
      'getConsultations',
      'getMoreConsultations',
      'creatConsultation',
      'setOpenConsultation',
      'setAllSeen',
      'sendReply'
    ]),
    onLoad() {
      this.intervalId = window.setInterval(async () => {
        await this.getConsultations()
      }, 30000)
    },
    titleRules(value) {
      if (!value) return this.$t('consultationTitleRequired')
      if (this.titleError) return this.titleError
      else return true
    },
    messageRules(value) {
      if (!value) return this.$t('consultationMessageRequired')
      if (this.messageError) return this.messageError
      else return true
    },
    resetValidation() {
      this.$refs.form.resetValidation()
    },
    scrollToEndOfConvo() {
      const el = this.$el.getElementsByClassName('convo')[0]
      if (el) el.scrollTop = el.scrollHeight
    },
    toggleMarker() {
      this.marker = !this.marker
    },
    openCreateConsultation() {
      this.createIsOpen = true
      this.showCurrentConsultation = false
    },
    checkIfIsNotSeen(item) {
      const lastMessage = item.messages[item.messages.length - 1]
      if (
        item.messages &&
        lastMessage.sender.email !== this.user.email &&
        !lastMessage.seen
      ) {
        return true
      } else return false
    },
    async sendMessage() {
      var formData = new FormData()
      formData.append('text', this.reply)
      formData.append('thread', this.currentConsultationThread.id)
      if (this.attachedFiles)
        this.attachedFiles.forEach(file =>
          formData.append('attached_files', file)
        )

      await this.sendReply(formData)
        .then(() => {
          this.clearMessage()
          this.scrollToEndOfConvo()
        })
        .catch(err => console.log(err))
    },
    clearMessage() {
      this.reply = ''
      this.attachedFiles = null
    },
    openItem(itemId) {
      this.setOpenConsultation(itemId)
      this.createIsOpen = false
      this.showCurrentConsultation = true
    },
    async createUserConsultation() {
      if (this.$refs.form.validate()) {
        await this.creatConsultation({
          title: this.title,
          message: this.message
        })
          .then(res => {
            if (!(res.status === 201 || res.status === 200)) {
              if (res.data.non_field_errors) {
                this.nonFieldErrors = res.data.non_field_errors[0]
              }
              if (res.data.title) {
                console.log('res', res)

                this.titleError = res.data.title[0]
              }
              if (res.data.message) {
                this.messageError = res.data.message[0]
              }
              this.$refs.form.validate()
            } else this.openItem(res.data.id)
          })
          .catch(err => console.log(err))
      }
    }
  }
})
</script>
<style lang="scss">
.consult_list {
  height: 55vh;
  overflow-y: auto;
}
</style>
