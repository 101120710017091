var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{staticClass:"mt-8"},_vm._l((_vm.consultationThreadMessages),function(message){return _c('v-row',{key:message.id},[_c('section',{staticClass:"d-flex-inline flex-row justify-center mb-5"},[_c('span',{class:[
            message.sender.email === _vm.user.email
              ? 'justify-end'
              : 'justify-start',
            'd-flex text-caption'
          ]},[_vm._v(" "+_vm._s(message.sender.first_name)+" "),_c('span',{staticClass:"primary--text px-2 font-weight-black "},[_vm._v(" | ")]),_vm._v(" "+_vm._s(_vm._f("moment")(_vm._f("toLocalTZ")(message.created_at),'D, MMM YYYY, h:mm a'))+" ")]),_c('div',{class:[
            message.sender.email === _vm.user.email
              ? 'justify-end'
              : 'justify-start',
            'd-flex '
          ]},[_c('div',{class:[
              message.sender.email === _vm.user.email
                ? 'talk-right__simple'
                : 'talk-left__simple',
              'card-bubble text-body-2 '
            ]},[_c('div',{staticClass:" white--text pa-3"},[_vm._v(" "+_vm._s(message.text)+" ")])])]),_c('span',{class:[
            message.sender.email === _vm.user.email
              ? 'justify-end'
              : 'justify-start',
            'd-flex px-4 py-2'
          ]},_vm._l((message.attached_files),function(file,i){return _c('a',{key:i,staticClass:" d-flex flex-column text-decoration-none col-3",attrs:{"href":file.url,"download":file.url}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-file-outline ")]),_c('span',{staticClass:"caption secondary--text  file-name "},[_vm._v(_vm._s(file.name)+" ")])],1)}),0)])])}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }